@import "styles/mixins";


.faq{
  text-align: left;
  margin-top: 72px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.faq__link{
  margin-top: 60px;
}
.faq__titleReg{
  line-height: 100%;
  display: block;
  margin-top: 28px;
}
.faq__down{
  display: block;
  transition: 0.3s;
  margin-left: 18px;
}
.faq__question{
  @include text('Ubuntu', 23, 23);
  color: #056aaa;
  display: flex;
  align-items: center;
}
.faqInf{
  width: 460px;
}
.faq__answer{
  margin-top: 20px;
}
.faq__item{
  margin-top: 25px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
  &:first-child{
    margin-top: 0;
  }
  &.active{
    .faq__down{
      transform: rotate(180deg);
    }
  }
}
.faq__list{
  margin: 0;
  margin-top: 40px;
}


.faq.wrapper {
	.faq__link {
		cursor: pointer;
	}

	.faq__item {
		&.active {
			.faq__answer {
				height: auto;
			}
		}

		.faq__answer {
			height: 0;
			transition: .5s;
		}
	}

	.faq__video {
		position: relative;
		max-width: 536px;
		width: 100%;

		img {
			width: 100%;
			opacity: 0;
		}
		.iframe-wrapper {
			position: absolute;
			left: 0;top: 0;right: 0;bottom: 0;

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}
}
