@import "styles/vars";
@import "styles/mixins";

.applicationTitles {
  display: flex;
  align-items: center;
}
.applicationTitle {
  text-align: left;
  margin-left: 12px;
}
.application {
  margin-top: 26px;
}
.application__form {
  margin-top: 6px;
  padding: 9px 20px 17px 14px;
  background: #ffffff;
}
.applicationTextsBl {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.application__about {
  display: block;
  // margin-left: 28px;
}
.application__form {
  display: flex;
  justify-content: space-between;
}
.application__form_p {
  padding: 20px 14px 85px;
  display: block;
}
.inputfile {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 38px;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.inputfile + label {
  width: 100%;
  display: block;
  text-align: left;
  cursor: pointer;
  padding: 8px 50px 8px 10px;
  transition: 0.3s;
  pointer-events: none;
  border: 1px solid #979797;
}
.inputfile:focus + label,
.inputfile:hover + label {
  border: 1px solid #686767;
}
.applicationTextsBl__input {
  position: relative;
  width: 338px;
}
.applicationTextsBl__icon {
  position: absolute;
  right: 13px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.applicationForms {
  display: flex;
  flex-wrap: wrap;
  width: 800px;
}
.applicationForms__inputs {
  padding: 8px;
  position: relative;
  background: rgba(59, 153, 252, 0.124863);
  margin-left: 20px;
  &_dis {
    background: rgba(205, 214, 222, 0.124863);
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
    }
  }
}
.applicationForms__inputsList {
  margin-top: -10px;
  margin-left: -20px;
  display: flex;
  flex-wrap: wrap;
}
.applicationForms__inputs {
  margin-top: 40px;
  text-align: left;
  width: 219px;
  &_mt8 {
    margin-top: 8px;
  }
  &_mt15 {
    margin-top: 15px;
  }
}
.applicationForms__input {
  display: block;
  text-align: left;
  background: transparent;
  border: none;
  width: 100%;
  outline: none;
  @include text("Lato", 16, 16);
  &::placeholder {
    @include text("Lato", 14, 16);
    color: #6a6a6a;
    font-style: italic;
  }

  &[type="date"]:invalid::-webkit-datetime-edit {
    color: #6a6a6a;
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    height: 1.3rem;
  }
}
.applicationForms__abs {
  position: absolute;
  right: 0;
  bottom: -20px;
}
.application__checkboxs {
  width: 456px;
  margin-top: 56px;
  margin-left: 24px;
  display: flex;
}
.application__checkbox {
  margin-left: 25px;
  &:first-child {
    margin-left: 0;
  }
}
.check:checked + .checkbox__text_2::before {
  height: 14px;
  width: 14px;
  background: #fff;
  border: 6px solid #3f95fd;
}
.applicationForms__inputs_big {
  width: 338px;
}
.applicationForms__inputs_big2 {
  width: 460px;
}

.applicationForms__inputs_big3 {
  width: 95%;
}
.applicationForms__quest {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  right: 13px;
  margin: auto;
  .svg {
    display: block;
  }
}
.applicationFormsAdd {
  border: 1px solid #979797;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 307px;
}
.applicationFormsAdd__img {
  width: 65px;
  height: auto;
}
.applicationFormsAdd__inputs {
  position: relative;
  margin-top: 20px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 2px solid #3f95fd;
  display: flex;
  justify-content: center;
  align-items: center;
  .svg {
    width: 22px;
    height: auto;
    rect {
      fill: #3f95fd;
    }
  }
}
.applicationFormsAdd__input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.applicationForms_wr {
  width: 580px;
  background: #ffffff;
  padding: 12px 36px 28px 14px;
  margin-top: 16px;
  .applicationForms__inputsList {
    margin-top: 6px;
  }
  .applicationForms__inputs {
    margin-top: 16px;
  }
}
.applicationTextsBl_v2 {
  justify-content: flex-start;
  .application__about {
    margin-left: 28px;
  }
}
.applicationForms__inputs_little {
  width: 99px;
}
.applicationForms__inputs_little3 {
  width: 60px;
}
.applicationForms__inputs_little_2 {
  width: 134px;
}
.applicationForms__inputs_all {
  width: 100%;
}
.applicationForms__value {
  position: relative;
  opacity: 0.4;
}
.applicationForms__inputs_line {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.applicationForms__inputs_description {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 20px;
  text-align: left;
  &_ml0 {
    margin-left: 0;
  }
}
.applicationForms__inputs_text {
  margin-left: 20px;
  text-align: left;
}
.applicationForms__questionIcon {
  display: block;
  path {
    transition: 0.3s;
  }
}
.applicationForms__question {
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: none;
  margin-left: 10px;
  &:hover {
    .applicationForms__questionIcon {
      path {
        fill: #a1b5cc;
      }
    }
  }
}
.applicationBtn {
  display: block;
  margin: 28px auto;
}
.applicationTitle__btn {
  margin-left: auto;
}
.application__text {
  margin: 28px 0 35px;
  text-align: left;
  width: 100%;
  max-width: 790px;
}
.application__texts {
  width: 100%;
  max-width: 680px;
  text-align: left;
}

.application__form {
  .decorContent__item {
    position: relative;

    .decorContent__texts {
      .block-title {
        margin-top: 3px;
      }
    }
  }
  .decorContent__lines {
    height: 100%;
    // position: relative;

    // img.arrow-down {
    // 	position: absolute;
    // 	left: 50%;
    // 	top: 150px;
    // 	transform: translate(-50%,-50%);
    // }

    .v-line {
      position: absolute;
      left: 13px;
      top: 5px;
      bottom: 0;
      background: #aaa;
      width: 2px;
      transform: translateY(20px);
    }
  }

  .docs-inputs {
    display: flex;
    flex-wrap: wrap;

    .procedureContent__tabContent_doc {
      label {
        height: 100%;
        cursor: pointer;

        input {
          display: none;
        }
      }
    }

    @media (max-width: 576px) {
      justify-content: center;
    }
  }
}

.formLine__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 15px 0 8px;
  border-bottom: 1px solid #000;
  &:last-child {
    padding-bottom: 30px;
  }
  &_start {
    align-items: flex-start;
  }
}
.formLine {
  width: 100%;
  text-align: left;
  position: relative;
  &_little {
    width: 680px;
  }
  &_middle {
    width: 850px;
  }
  &_nb {
    .formLine__list {
      border-bottom: none;
    }
  }
}
.formLine__table {
  width: 100%;
}
.formLine__list_title {
  display: flex;
  flex-wrap: wrap;
  align-items: inherit;
  padding: 0;
  border: none;
}
.formLine__item_title {
  padding: 9px;
  background: rgba(59, 153, 252, 0.124863);
}
.formLine__item {
  word-wrap: break-word;
  &_52 {
    width: 52px;
  }
  &_60 {
    width: 60px;
  }
  &_80 {
    width: 80px;
  }
  &_102 {
    width: 102px;
  }
  &_108 {
    width: 108px;
  }
  &_158 {
    width: 158px;
  }
  &_170 {
    width: 170px;
  }
  &_200 {
    width: 200px;
  }
  &_218 {
    width: 218px;
  }
  &_230 {
    width: 230px;
  }
  &_258 {
    width: 258px;
  }
  &_276 {
    width: 276px;
  }
  &_410 {
    width: 410px;
  }
  &_440 {
    width: 440px;
  }
}

.formLine__input {
  border: 1px solid #000000;
  width: 100%;
  outline: none;
  background: transparent;
  padding: 7px 8px;
  resize: none;
  font-size: 12px;
  @include text("Lato", 12, 14);
  &_textarea {
    height: 70px;
    display: block;
  }
  &::placeholder {
    @include text("Lato", 12, 14);
    color: #6a6a6a;
  }
}
.formLine__item_number {
  padding: 10px 12px;
}
.formLine__titles {
  display: block;
  padding: 0 6px;
}
.formLine__add {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 2px solid #3f95fd;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -18px;
  transition: 0.3s;
  &:hover {
    background: rgb(233, 233, 233);
  }
}

.formLine__remove {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 2px solid #3f95fd;
  position: absolute;
  left: 0;
  right: 100px;
  margin: auto;
  bottom: -18px;
  transition: 0.3s;
  &:hover {
    background: rgb(233, 233, 233);
  }
}

.formLine__deals {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 2px solid #3f95fd;
  margin: auto;
  margin-top: 10px;
  transition: 0.3s;
  &:hover {
    background: rgb(233, 233, 233);
  }
}
