.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.tooltip-box {
  transform: translateX(-47%);
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  top: calc(100% + 10px);
  display: none;
  width: 128px;
  z-index: 1;
}

.tooltip-box.visible {
  display: flex;
}

.tooltip-arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
}
