@import "styles/vars";
@import "styles/mixins";


.about{
  padding: 20px 140px 20px 20px;
  text-align: left;
  background: $formBg;
  border: 1px solid $borderGrey;
  position: relative;
}
.about_mt{
  margin-top: 30px;
}
.about__text{
  margin-top: 28px;
}
.about__title{
  margin-top: 28px;
}
.about__ad{
  display: block;
  margin-top: 12px;
}
.about_btn{
  margin-top: 14px;
}
