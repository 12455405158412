@import "https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap";
@import "https://fonts.googleapis.com/css?family=Ubuntu+Condensed&display=swap";
@import "https://fonts.googleapis.com/css?family=Lato&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto&display=swap";


@font-face {
  font-family: "Pragmatica";
  src: url("./fonts/Pragmatica-Medium.woff") format("woff")
}
