@import "styles/vars";
@import "styles/mixins";


.titleBlock{
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  color: #000000;
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.settings{
  display: flex;
  align-items: flex-start;
  width: 1300px;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.settings_mt{
  margin-top: 28px;
}
.settings__block{
  width: 640px;
  border: 4px solid #f0f0f0;
  padding: 45px 50px;
  background-color: #ffffff;
}
.settings__block{
  margin-top: 14px;
}
.body_bl{
  background: #f7f9f8;
}
.settings__titles{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings__parametr, .settings__value{
  @include text('Ubuntu', 18, 18);
  color: #000000;
}
.settings__item{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}
.settings__list{
  margin-top: 44px;
}
.settings__parametr{
  width: 30%;
}
.settings__value{
  width: 65%;
}
.settings__qustion{
  @include text('Ubuntu', 21, 21);
  color:  #056aaa;
  width: 30px;
  height: 30px;
  border: 2px solid #056aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  &:hover{
    background: rgb(243, 243, 243);
  }
}
.settings__parametr_quest{
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  align-items: center;
}
.settings__qustion{
  margin-left: 10px;
  position: relative;
}
.settings__answer{
  position: absolute;
  right: -350px;
  opacity: 0;
  background: #fff;
  z-index: 3;
  width: 340px;
  top: -20px;
  padding: 14px;
  transform: scaleX(0);
  transform-origin: left center;
  border: 1px solid #f0f0f0;
  &.active{
    opacity: 1;
    transform: scaleX(1);
  }
}
.settings__item_absStyle{
  display: flex;
  width: 380px;
  justify-content: flex-start;
  .settings__link{
    width: 320px;
  }
  .settings__qustion{
    margin-left: 20px;
  }
}
.confirmBlock_btn{
  margin-top: 52px;
}
.confirmBlock__gos{
  display: block;
  margin-top: 24px;
}
.confirmBlock__datas{
  display: flex;
  margin-top: 37px;
}
.confirmBlock__data{
  width: 50%;
}
.confirmBlock__indefication{
  display: block;
  margin-top: 16px;
}
.confirmBlock__text{
  margin-top: 32px;
}
.settings__down{
  width: 28px;
  height: 28px;
  border: 2px solid #006b95;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active{
    .settings__downIcon{
      transform: rotate(180deg);
    }
  }
}
.settings__titles_acc{
  cursor: pointer;
  &:hover{
    .settings__down{
      background: rgb(250, 250, 250);
    }
  }
}
.settings__dop{
  width: 100%;
  margin-top: 35px;
  padding: 25px 50px 25px 25px;
  border: 1px solid #cccccc;
  background-color: #f8f8f8;
  position: relative;
}
.settings__close{
  position: absolute;
  cursor: pointer;
  top: 19px;
  right: 19px;
}
.settings__closeIcon{
  g{
    opacity: 1;
  }
}
.settings__texts{
  @include text('Ubuntu', 18, 35);
  .link_20{
    font-size: 18px;
  }
}
.checking{
  width: 30px;
  height: 30px;
  border: 1px solid #9bba3c;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.check__icon{
  width: 18px;
  height: auto;
  path{
    stroke:  #9bba3c;
    fill: #9bba3c;
  }
}
.settings__value_v2{
  width: auto;
  display: flex;
  position: relative;
  max-width: 340px;
}
.settings__item_2{
  justify-content: flex-start;
}
.checking__position{
  position: absolute;
  right: -40px;
}
.settings__btn{
  margin-top: 45px;
}
.settings__btn_2{
  margin-top: 30px;
}
.settings__down_add{
  max-height: 0;
  overflow: hidden;
  &.active{
    max-height: 1000px;
  }
}
