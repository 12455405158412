.modal {
	$self: &;
	position: fixed;
	left: 0;top: 0;
	z-index: 100;
	overflow: hidden;
	width: 0.01px;
	height: 0.01px;
	background: rgba(0,0,0,.8);
	opacity: 0;
	transition: opacity .3s;

	&--is-visible {
		right: 0;bottom: 0;
		width: 100vw;
		height: 100vh;
		opacity: 1;

		#{$self}__content-wrap {
			transform: scale(1);
		}
	}

	&--width-auto {
		#{$self}__content-wrap {
			max-width: none;
			width: auto;
		}
	}

	.twosign, .about {
		border-radius: 3px;
		border: none;
	}

	&__wrapper {
		position: absolute;
		left: 0;top: 0;right: 0;bottom: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: auto;
		padding: 50px;
	}

	&__empty-space {
		position: absolute;
		left: 0;top: 0;right: 0;bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	&__content-wrap {
		background: #fff;
		border-radius: 2px;
		max-width: 700px;
		width: 100%;
		z-index: 10;
		transition: transform .25s;
		// transform: scale(0.8);
	}

	&__header {
		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #ddd;
			padding: 0 0 0 20px;
			height: 50px;
		}
		&__right {
			height: 100%;

			button {
				width: 50px;
				height: 100%;
				padding: 10px;
				background: none;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #aaa;
				transition: color .3s;

				&:hover, &:focus {
					color: #333;
				}
			}
		}
	}

	&__content {
		padding: 15px 20px;
	}

	&__footer {
		&__inner {
			display: flex;
			justify-content: flex-end;
			border-top: 1px solid #ddd;
			padding: 15px 20px;

			button {
				margin-left: 10px;
			}
		}
	}

	@media (max-width: 768px) {
		#{$self}__wrapper {
			padding: 40px 25px;
		}
	}
}
