@mixin text ($font-family, $fz, $line-h) {
  font-family:  $font-family, sans-serif;
  font-size: $fz + px;
  line-height: $line-h + px;
}
@mixin wrapper ($width) {
  width: $width;
  text-align: center;
  margin: 0 auto;
}
