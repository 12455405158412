@import "styles/vars";
@import "styles/mixins";


.notification {
	$self: &;
	$height: 50px;

	border-bottom: 1px solid #ddd;

	&:last-child {
		border-bottom: none;
	}

	&--is-open {
		#{$self}__row {
			height: auto;
		}
		#{$self}__text {
			white-space: normal;
			text-overflow: ellipsis;
			overflow: auto;
		}
	}

	&__row {
		display: flex;
		height: 50px;
		position: relative;
		// cursor: pointer;
	}

	&__icon {
		margin-top: 13px;

		.img {
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: 25px;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid #0075DB;

			img {
				width: 14px;
			}
		}
	}

	&__text {
		width: 100%;
		padding-top: 15px;
		margin: 0 10px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-bottom: 10px;
		text-align: left;
	}

	&__date {
		white-space: nowrap;
		color: #aaa;
		font-size: 14px;
		line-height: $height;
	}

	@media (max-width: 768px) {
		#{$self}__icon {
			margin-top: 15px;
		}
		#{$self}__text {
			padding-top: 17px;
			margin-right: 5px;
		}
		#{$self}__date {
			position: absolute;
			right: 0;
			top: 3px;
			line-height: 1;
			font-size: 12px;
		}
	}
}
