.telegram_modal {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal_footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
