@import "./libs/antd.scss";
@import "./libs/normalize.min.css";

// Components
@import "./components/AboutModal.scss";
@import "./components/SettingsModal.scss";

// Modules
@import "./modules/Application.scss";
@import "./modules/Auth.scss";
