.result-page {
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;

  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .block-title {
    font-size: 25px;
  }

  .content-left {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;

    .docs-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      ul {
        margin-top: 0;
      }

      .block {
        max-width: calc(80% - 20px);
        width: 100%;

        &-title {
          margin-bottom: 20px;
        }

        .pdf-container {
          max-height: 842px;
        }
      }

      @media (max-width: 1060px) {
        .block {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }
      }
    }

    ul {
      width: 100%;
      max-height: 842px;
      overflow-y: auto;
      background: #fff;
      padding: 20px 20px 10px;
      position: relative;
      margin-top: 20px;

      li {
        display: flex;
        padding-bottom: 20px;
        text-align: left;
        position: relative;
        z-index: 10;

        &::after {
          content: "";
          position: absolute;
          left: 13px;
          top: 26px;
          bottom: 0;
          width: 2px;
          margin-left: -1px;
          background: #999;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }

        .decorContent__linesCircle2 {
          min-width: 26px;
          margin-right: 10px;
          font-size: 16px;
        }
        span {
          margin-top: 3px;
        }
      }
    }

    .payment-block {
      margin-top: 24px;

      button.download {
        background: #999;
        max-width: none;
      }

      button.primary {
        background: #559634 !important;
        display: flex;
        margin: 12px auto;
        max-width: 800px;
        width: 100%;
        max-width: none;
      }

      button.last_step{
        background: #559634 !important;
        margin: 12px 15%;
      }
    }
  }

  .content-right {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    iframe {
      margin-top: 20px;
      width: 100%;
      height: 900px;
    }
  }

  .payment-block {
    text-align: center;
    margin-top: 20px;
    width: 100%;

    button.download {
      display: flex;
      margin: 0 auto;
      max-width: 800px;
      width: 100%;
      max-width: none;
    }
  }

  @media (max-width: 992px) {
    .content-right {
      iframe {
        width: 100%;
        height: 300px;
      }
    }
  }
}
