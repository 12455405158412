@import "./fonts";
@import "./vars";
@import "./mixins";

body,
#root {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu Condensed", sans-serif;
  background: $bodyGrey;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.body_white {
  background: #fff;
}
html {
  height: 100%;
}
* {
  box-sizing: border-box;
}
ul {
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}

.header {
  /* 0 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 0 0 auto;
}
.main {
  /* 1 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 1 0 auto;
}
.footer {
  /* 0 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 0 0 auto;
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
}

.text_center {
  text-align: center;
}

.text_11 {
  @include text("Lato", 11, 11);
  color: $dgrey2;
}
.text_12 {
  @include text("Lato", 12, 12);
  color: #000;
}
.text_12_14 {
  @include text("Lato", 12, 14);
  color: #000;
}
.text_greys {
  @include text("Lato", 14, 14);
  color: $greyText;
}
.text {
  @include text("Lato", 16, 19);
  color: $dgrey;
}
.text_2 {
  @include text("Lato", 16, 19);
  color: $dgrey2;
}
.text_14 {
  @include text("Lato", 14, 14);
  color: $dgrey2;
}
.text_14_17 {
  @include text("Lato", 14, 17);
  color: $dgrey2;
}
.text_15 {
  @include text("Ubuntu", 15, 15);
  color: #696969;
}
.text_16 {
  @include text("Ubuntu", 16, 16);
}
.text_18 {
  @include text("Ubuntu Condensed", 18, 18);
  color: $greyText;
}
.text_18_2 {
  @include text("Ubuntu", 18, 18);
  color: #636363;
}
.dtext_18 {
  @include text("Ubuntu Condensed", 18, 18);
  color: $dgrey2;
}
.text_18_21 {
  @include text("Ubuntu Condensed", 18, 21);
  color: $dgrey2;
}
.text_18_30 {
  @include text("Ubuntu", 18, 30);
  color: #000000;
}
.text_22 {
  @include text("Ubuntu", 22, 22);
  font-weight: 700;
}
.text_23 {
  @include text("Ubuntu", 23, 23);
  font-weight: 500;
  color: #414141;
}
.text_grey {
  @include text("Lato", 16, 19);
  color: $grey;
}
.link {
  @include text("Lato", 14, 14);
  color: #fff;
  &:hover {
    color: rgb(219, 218, 218);
  }
}
.link_20 {
  @include text("Ubuntu", 20, 20);
  color: #056aaa;
  &:hover {
    color: #03588d;
  }
}
.title_27 {
  @include text("Ubuntu Condensed", 27, 27);
  color: #000000;
}
.title {
  font-size: 40px;
}
.title_28 {
  font-size: 28px;
  color: $dgrey2;
}
.title_35 {
  @include text("Ubuntu", 35, 35);
  font-weight: 500;
}
.title_53 {
  @include text("Ubuntu Condensed", 53, 53);
}
.bold {
  font-weight: 700;
}
.medium {
  font-weight: 500;
}
.red {
  color: $red;
}
.blue {
  color: $blue;
}
.dblue {
  color: $dblue;
}
.green {
  color: $greenCheck;
}
.white {
  color: #fff;
}
.greyText {
  color: $greyText;
}
.wrapper__little {
  @include wrapper(334px);
}
.wrapper__middle {
  @include wrapper(700px);
}
.wrapper__big {
  @include wrapper(1400px);
}
.wrapper {
  @include wrapper(1060px);
}
.null {
  margin: 0;
  padding: 0;
}
.logo {
  // width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #ffffff;
}
.form {
  width: 305px;
  box-shadow: 1px 3px 4px 1px rgba(0, 0, 0, 0.11);
  margin: 0 auto;
  background: $formBg;
  padding: 20px 20px 28px;
  border: 1px solid $borderGrey;
}
.input {
  display: block;
  width: 100%;
  outline: none;
  border: 1px solid $borderGrey;
  padding: 9px 10px;
  @include text("Lato", 14, 14);
  color: $dgrey2;
}
.input_mt {
  margin-top: 26px;
}
.input_mt2 {
  margin-top: 10px;
}

.footer * {
  font-family: 'Lato', sans-serif;
}

.footer__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
}

.footer__socials {
  display: flex;
  align-items: cneter;
  gap: .5rem;
}

.footer__socials img {
  width: 24px;
  height: 24px;
}

.footer__right-part {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.footer__phone-number {
    font-size: 1.25rem;
    font-weight: 500;
}

.footer__phone-number a {
  color: inherit;
}

@media (min-width: 768px) {
    .footer__right-part {
        gap: 30px;
    }
}

.footer__bottom {
    margin-top: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    gap: 20px;
    border-top: 1px solid #ccc; /* Линия между уровнями футера */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.check {
  display: none;
}
.trz {
  transition: 0.3s;
}
.checkbox__text {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.checkbox_mt {
  margin-top: 10px;
}
.checkbox_ml {
  margin-left: 40px;
}
.checkbox__text {
  position: relative;
  &::before {
    content: "";
    color: transparent;
    display: block;
    border: 2px solid #3f95fd;
    height: 12px;
    width: 12px;
    background: #fff;
    transition: 0.3s;
    margin-right: 8px;
  }
}
.check:checked + .checkbox__text::before {
  background: #3f95fd;
}
.button {
  padding: 12px 0;
  text-align: center;
  width: 265px;
  outline: none;
  display: block;
  background: $green;
  border: 0px;
  transition: 0.3s;
  cursor: pointer;
  @include text("Lato", 14, 14);
  color: #fff;

  &_disabled {
    opacity: 0.8;
    background: #999;
    cursor: not-allowed;

    &:hover {
      background: #999 !important;
    }
  }

  &:hover {
    background: $greenHover;
  }
  &:active {
    background: $greenActive;
  }
  &.little {
    width: 177px;
  }
  &_grey {
    background: #dfdfdf;
    color: #6a6a6a;
    &:hover {
      background: #c7c7c7;
    }
    &:active {
      background: #b1b1b1;
    }
  }

  &_aqua {
    background: #0075db;
    color: #fff;
    &:hover {
      background: #3f95fd;
    }
    &:active {
      background: #3f95fd;
    }
  }
}
.button_primary {
  border-radius: 4px;
  background-color: #0075db;
  border: 4px solid transparent;
  padding: 14px 26px;
  width: auto;
  @include text("Ubuntu", 20, 20);
  &:hover {
    background-color: #3f95fd;
  }
}
.button_primary_outline {
  border-radius: 4px;
  background-color: #fff;
  border: 4px solid #0075db;
  color: #0075db;
  padding: 14px 26px;
  width: auto;
  @include text("Ubuntu", 20, 20);
  &:hover {
    border: 4px solid #3f95fd;
    color: #3f95fd;
  }
}
.button_secondary {
  border-radius: 4px;
  background-color: #559634;
  padding: 18px 30px;
  width: auto;
  @include text("Ubuntu", 20, 20);
  &:hover {
    background-color: #4c852f;
  }
}
.button_blue {
  border-radius: 4px;
  background-color: #0162af;
  padding: 22px 30px;
  width: auto;
  @include text("Calibri", 23, 23);
  &:hover {
    background-color: #014f8f;
  }
  &.v2 {
    width: 100%;
    @include text("Ubuntu", 20, 20);
    padding: 14px 0px;
  }
}
.button_transparent {
  border-radius: 4px;
  border: 1px solid #056aaa;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 16px 18px;
  width: auto;
  @include text("Ubuntu", 20, 20);
  color: #056aaa;
  &:hover {
    background: #056aaa;
    color: #fff;
    .settings__plus {
      path {
        stroke: #fff;
      }
    }
  }
}
.link_blue {
  color: $purple;
  &:hover {
    color: $purpleHover;
  }
}
.bottom_footer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}
.header {
  padding: 9px 0;
  background: $dblue;
}
.header_wrapper {
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header__nav {
  margin-left: 20px;
}
.header__list {
  display: flex;
  flex-wrap: wrap;
}
.header__item {
  margin-left: 38px;
  &:first-child {
    margin-left: 0;
  }
}
.search_ml {
  margin-left: 35px;
}
.search {
  cursor: pointer;
}
.search__icon {
  display: block;
  &:hover {
    path {
      fill: rgb(219, 219, 219);
    }
  }
}
.user__text {
  display: block;
  margin-left: 14px;
  margin-right: 11px;
}
.user {
  display: flex;
  align-items: center;
  &:hover {
    .text_14 {
      color: rgb(219, 218, 218);
    }
  }
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border: 2px solid #fff;
}

.login-btn {
  // width: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  a {
    color: #ffffff;
  }
  span {
    color: #ffffff;
  }
}

.user_ml {
  margin-left: auto;
}

.map_ml {
  margin-left: 11px;
}
.map {
  display: flex;
  padding-left: 12px;
  align-items: center;
  border-left: 1px solid #fff;
  cursor: pointer;
  &:hover {
    .text {
      color: rgb(219, 218, 218);
    }
  }
}
.map__text {
  display: block;
  margin-left: 14px;
}
.breadCrumbs {
  background: #e6e4e4;
  position: relative;
}
.breadCrumbs__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 360px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #000000;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.breadCrumbs__btn {
  width: 22px;
  height: 22px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
  z-index: 3;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #5fa6fd;
    color: #fff;
  }
  &.active {
    background: #3f95fd;
    color: #fff;
  }
}
.breadCrumbs__link {
  color: #6a6a6a;
  display: block;
  &.active {
    color: #5f5f5f;
  }
  &:hover {
    color: #5f5f5f;
  }
}

.breadCrumbs__list {
  display: flex;
  align-items: center;
  min-height: 36px;
}
.breadCrumbs__item {
  // margin-left: 18px;
  margin-left: -5px;
  display: flex;
  align-items: center;
  &:first-child {
    margin-left: 0;
    padding-left: 0;
    border-left: 0px;
  }
}
.homeIcon {
  margin-right: 8px;
  display: inline-block;
}
.footer {
  background: #e4e4e4;
  padding: 14px 26px 30px;
  bottom: 0;
  left: 0;
  width: 100%;
}
.footer__list {
  display: flex;
}
.footer__item {
  margin-left: 28px;
  &:first-child {
    margin-left: 0;
  }
}
.back {
  border: 1px solid #979797;
  padding: 7px 8px;
  display: flex;
  &:hover {
    background: rgb(235, 235, 235);
  }
}
.back_link {
  padding: 18px 30px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  width: 238px;
  border: 1px solid #acacac;
  &:hover {
    background: rgb(250, 250, 250);
  }
}
.back_link__icon {
  display: block;
  margin-right: 10px;
}
.back__icon {
  transform: rotate(180deg);
}
.back__text {
  display: block;
  margin-left: 10px;
}
.next {
  display: flex;
  align-items: center;
  text-align: left;
}
.next_ml {
  margin-left: 20px;
}
.next__text {
  display: block;
}
.next__icon {
  display: block;
  margin-left: 12px;
}

.allLinks {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  li {
    margin-top: 20px;
  }
}
.header_none {
  padding: 0;
}

.header_abs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1160px;
}
.header__list {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.search__input {
  display: none;
  background: transparent;
  border: none;
  outline: none;
  padding: 3px 2px;
  border-bottom: 1px solid #ffffff;
  &::placeholder {
    color: #fff;
  }
}
.procedureContent__icon {
  display: none;
}
.decorContentText__icon {
  margin-left: 11px;
  display: block;
}
.addImage {
  &.down {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}
.breadCrumbs__link {
  color: #555555;
  @include text("Ubuntu", 15, 15);
  font-weight: 700;
  &.active {
    color: #a2a2a2;
    font-weight: 400;
  }
}
.breadCrumbs__right {
  display: block;
  margin-right: 18px;
  path {
    stroke: #d6d5d5;
    fill: #d6d5d5;
  }
  g {
    opacity: 1;
  }
}
.breadCrumbs_light {
  background: #f6f6f6;
  .breadCrumbs__right {
    path {
      stroke: #e1e1e1;
      fill: #e1e1e1;
    }
  }
}
.settings__plus {
  display: block;
  margin-right: 8px;
  g {
    opacity: 1;
  }
}
.password_mt {
  margin-top: 48px;
}
.password {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.password__text {
  color: #000;
}
.password__inputBlock {
  width: 470px;
  position: relative;
  text-align: left;
}
.password__input {
  border: 1px solid #cccccc;
  padding: 16px 10px;
  &:hover {
    background-color: #fffac6;
  }
  &:focus {
    background-color: #fffac6;
  }
}
.password__show {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  color: #056aaa;
  @include text("Ubuntu", 17, 17);
  bottom: 0;
  right: 16px;
  margin: auto;
  opacity: 0;
  cursor: pointer;
  &.active {
    opacity: 1;
  }
}
.password_mt2 {
  margin-top: 30px;
}
.passwordGenerate {
  color: #056aaa;
  display: inline-block;
  cursor: pointer;
  @include text("Ubuntu", 17, 17);
  border-bottom: 1px dashed #056aaa;
}
.password__inputBlock_generate {
  margin-top: 30px;
  margin-left: auto;
}
.twoBtns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;
}
.twoBtns__btn {
  @include text("Ubuntu", 20, 20);
  padding: 20px 26px;
  &.noactive {
    background-color: #d6d6d6;
  }
}
.change__texts {
  text-align: left;
  margin-top: 35px;
}
.twoBtns_v2 {
  justify-content: flex-start;
  .button_transparent {
    margin-left: auto;
  }
  .button_blue {
    margin-left: 20px;
  }
}
.preview {
  width: 100%;
  height: auto;
}
.redStar {
  font-size: 17px;
  line-height: 20px;
  color: #ff4040;
  @include text("Lato", 17, 20);
  right: -10px;
  top: -4px;
}

.mb {
  &_5 {
    margin-bottom: 5px;
  }
  &_10 {
    margin-bottom: 10px;
  }
  &_12 {
    margin-bottom: 12px;
  }
  &_15 {
    margin-bottom: 15px;
  }
  &_20 {
    margin-bottom: 20px;
  }
  &_30 {
    margin-bottom: 30px;
  }
  &_40 {
    margin-bottom: 40px;
  }
  &_60 {
    margin-bottom: 60px;
  }
}

.mt {
  &_5 {
    margin-top: 5px;
  }
  &_10 {
    margin-top: 10px;
  }
  &_12 {
    margin-top: 12px;
  }
  &_15 {
    margin-top: 15px;
  }
  &_20 {
    margin-top: 20px;
  }
  &_25 {
    margin-top: 25px;
  }
  &_30 {
    margin-top: 30px;
  }
  &_40 {
    margin-top: 40px;
  }
  &_60 {
    margin-top: 60px;
  }
  &_70 {
    margin-top: 70px;
  }
}

.ml {
  &_5 {
    margin-left: 5px;
  }
  &_10 {
    margin-left: 10px;
  }
  &_12 {
    margin-left: 12px;
  }
  &_15 {
    margin-left: 15px;
  }
  &_20 {
    margin-left: 20px;
  }
  &_30 {
    margin-left: 30px;
  }
  &_40 {
    margin-left: 40px;
  }
  &_60 {
    margin-left: 60px;
  }
}

.mr {
  &_5 {
    margin-right: 5px;
  }
  &_10 {
    margin-right: 10px;
  }
  &_12 {
    margin-right: 12px;
  }
  &_15 {
    margin-right: 15px;
  }
  &_20 {
    margin-right: 20px;
  }
  &_30 {
    margin-right: 30px;
  }
  &_40 {
    margin-right: 40px;
  }
  &_60 {
    margin-right: 60px;
  }
}

.h_100 {
  height: 100%;
}

.p {
  &_5 {
    padding: 5px;
  }
  &_10 {
    padding: 10px;
  }
  &_12 {
    padding: 12px;
  }
  &_15 {
    padding: 15px;
  }
  &_20 {
    padding: 20px;
  }
  &_30 {
    padding: 30px;
  }
  &_40 {
    padding: 40px;
  }
  &_60 {
    padding: 60px;
  }
}

.w_100 {
  width: 100%;
}

.w_200 {
  width: 200px;
}

.block {
  display: block;
}
.inline-block {
  display: inline-block;
}

.bg_white {
  background-color: #fff;
}

@import "./includes";
@import "./media.scss";
