.entrance{
  margin-top: 44px;
}
.entrance__about{
  margin: 10px 0 16px;
}
.entrance_btn{
  margin-top: 34px;
}
.entrance_btn2{
  margin-top: 24px;
}
.entrance__password{
  display: inline-block;
  margin-top: 12px;
  padding-bottom: 2px;
  border-bottom: 1px solid #D5D5D5;
  &:hover{
    border-bottom: 1px solid rgb(161, 161, 161);
  }
}
.entrance__texts{
  display: block;
  margin-top: 11px;
}
.footer_entrance{
  width: 305px;
  margin: 0 auto;
  text-align: center;
  position: static;
  padding: 26px 0;
  margin-top: 20px;
  .footer__list {
    flex-direction: column;
  }
}
.footer__item_entrance{
  margin-top: 12px;
  margin-left: 0;
  &:first-child{
    margin-top: 0;
  }
}
.entrance__text{
  line-height: 19px;
}
.entrance__mt{
  display: block;
  margin-top: 14px;
}
.entrance__mt2{
  display: block;
  margin-top: 28px;
}
