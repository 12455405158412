.confirm{
  width: 1268px;
  margin: 0 auto;
  text-align: left;
}
.confirm_mt{
  margin-top: 25px;
}
.confirmBlock{
  width: 100%;
  display: flex;
  margin-top: 54px;
  justify-content: space-between;
}
.confirmBlock__column{
  width: 50%;
  padding: 29px 34px;
  background-color: #fafafa;
  &:nth-child(2){
    background: #f5f7fa;
  }
}
.confirm__price{
  display: block;
  margin-top: 32px;
}
.confirm__inf{
  margin-top: 26px;
  .bold{
    color: #000;
  }
}
