@import "styles/vars";
@import "styles/mixins";


.twosign{
  width: 820px;
  text-align: center;
  margin: 0 auto;
  padding: 60px;
  background: #fff;
}

.attention_mt{
  margin-top: 38px;
}
.attention{
  border: 1px solid #cccccc;
  border-top: 5px solid #ffc83d;
  padding: 28px 0 94px;
}
.attention__v{
  width: 30px;
  height: 30px;
  border: 2px solid #ffc83d;
  @include text('Ubuntu', 21, 21);
  color: #ffc83d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.attention__content{
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 585px;
}
.attention__text{
  text-align: left;
  width: 534px;
}
