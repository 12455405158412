.modal__content-wrap {
  width: auto;
}

.collect_form {
  .button_primary {
    margin: 20px auto auto;
    display: inherit;
  }
}
