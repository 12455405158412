// Color
$red: #F04664;
$blue: #3F95FD;
$dblue: #0075DB;
$grey: #A2A2A2;
$greyText: #5F5F5F;
$dgrey: #4A4A4A;
$dgrey2: #545454;
$borderGrey: #979797;
$bodyGrey: #F2F2F2;
$formBg: #FDFDFD;
$green: #559634;
$greenCheck: #3BB54A;
$greenHover: #4c852f;
$greenActive: #407028;
$purple: #7659F4;
$purpleHover: rgb(108, 82, 223);
// Width
