@import "styles/vars";
@import "styles/mixins";


.decor{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 28px;
}
.decorContent{
  width: 700px;
}
.decorContent__title{
  display: flex;
  align-items: center;
}
.decorContent__titleText{
  margin-left: 16px;
  text-align: left;
}
.decorContentFunc{
  display: flex;
  align-items: center;
}
.decorContentFunc{
  margin-top: 14px;
}
.decorContentTexts{
  margin-top: 28px;
}
.decorContentText{
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &:first-child{
    margin-top: 0;
  }
}
.decorContentText__about{
  display: block;
  margin-left: 15px;
  margin-top: 3px;
}
.decorContentText__price{
  @include text('Ubuntu Condensed', 18, 18);
  color: $grey;
  display: block;
  margin-left: 20px;
  text-decoration-line: line-through;
  margin-top: 2px;
}
.decorContentAbout_mt{
  margin-top: 26px;
}
.decorContentAbout{
  background: #E6E5EC;
  width: 100%;
  padding: 15px 55px 20px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.decorContentAbout__content{
  width: 525px;
  text-align: left;
}
.decorContentAbout__title{
  color: #5F5F5F;
}
.decorContentAbout__texts{
  color: #808080;
  margin-top: 16px;
}
.decorContentAbout__link{
  display: block;
  &:hover{
    color: rgb(51, 160, 64);
  }
}
.decorContent__list{
  text-align: left;
  margin: 0;
  margin-top: 26px;
}
.decorContent__item{
  display: flex;
  align-items: flex-start;
  margin-top: 14px;
  &:first-child{
    margin-top: 0;
  }
}
.decorContent__texts{
  margin-left: 11px;
  .text_18{
    color: #000;
    display: block;
  }
}
.decorContent__lines{
  width: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.decorContent__linesCircle{
  background: #FFFFFF;
  border:6px solid #3F95FD;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.decorContent__line{
  margin-top: 10px;
  width: 2px;height: 86px;
  background: $borderGrey;
}
.checkbox__text_2{
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.checkbox_mt{
  margin-top: 10px;
}
.checkbox__text_2 {
  position: relative;
  &::before {
    content: "";
    color: transparent;
    display: block;
    border: 2px solid #A2A2A2;
    height: 22px;
    width: 22px;
    background: #fff;
    transition: .3s;
    border-radius: 100%;
    margin-right: 8px;
  }
}
.check:checked + .checkbox__text_2::before {
  height: 14px;
  width: 14px;
  background: #fff;
  border:6px solid #A2A2A2;
}
.decorContent__checkboxs{
  display: flex;
  margin-top: 20px;
}
.decorContent__checkbox{
  margin-left: 32px;
  &:first-child{
    margin-left: 0;
  }
}
.decorContent__linesCircle2{
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background: #FFFFFF;
  border: 2px solid #3F95FD;
  color: #000;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
}
.decorContent__textList{
  margin-top: 18px;
}
.decorContent__textItem{
  margin-top: 6px;
  display: flex;
  align-items: center;
  &::before{
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: $dgrey2;
    margin-right: 6px;
  }
  &:first-child{
    margin-top: 0;
  }
}
.decorAdv{
  width: 202px;
  margin-top: 48px;
  text-align: left;
}
.decorAdv__title{
  margin: 14px 0;
}
.decorAdv__back{
  display: block;
  margin-left: 14px;
  path{
    fill: #fff;
  }
}
.decorAdv_btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
}
.decorAdv_btn.button.little {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.decorAdv__about{
  color: #6A6A6A;
  display: block;
  margin-top: 10px;
}
.decorAdvTitles{
  display: block;
  margin-top: 40px;
  margin-bottom: 15px;
}
.decorAdv__link{
  padding-bottom: 3px;
  border-bottom: 1px dashed #979797;
}
.decorAdv__item{
  margin-top: 5px;
}


.decor.wrapper {
	.decorContent__item {
		a {
			label {
				color: #3bb54a;
			}
		}
	}
}
