@media (max-width: 1440px){
  .wrapper__big{
    width: 95%;
  }
  .header_abs{
    width: 82%;
  }
}
@media (max-width: 1350px){
  .procedureUser{
    margin-left: 0;
  }
  .settings{
    width: 95%;
    justify-content: space-around;
  }
  .settings_mt{
    margin-top: 1px;
  }
  .confirm{
    width: 95%;
  }
  .user_ml {
    .map_ml {
      display: none;
    }
    // span {
    //   display: none;
    // }
  }
}
@media (max-width: 1150px){
  .wrapper{
    width: 95%;
  }
  .menu_btn{
    display: none;
    margin-left: auto;
    height: 24px;
  }
  .header{
    overflow: hidden;
  }
  .header_abs{
    width: 50%;
    z-index: 4;
    position: absolute;
    right: 0;
    padding: 15px 30px;
    top: 47px;
    opacity: 0;
    background: $dblue;
    flex-wrap: wrap;
    transform-origin: center right;
    transform:scaleX(0);
    &.active{
      opacity:1;
      transform: scaleX(1);
    }
  }
  .header__list{
    flex-direction: column;
  }
  .header__item{
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
    &:first-child{
      margin-top: 0;
    }
  }
  .header__nav{
    margin-left: 0;
    width: 100%;
    text-align: left;
  }
  .header__link{
    font-size: 18px;
  }
  .search{
    display: flex;
    width: 100%;
    margin: 0;
    margin-top: 15px;
  }
  .search__input{
    display: block;
    margin-left: 10px;
    width: 90%;
  }
  .user_ml{
    margin-left: 0;
    margin-top: 20px;
  }
  .map_ml{
    margin-top: 25px;
  }
  .procedure {
    justify-content: flex-start;
  }
  .procedureContents{
    flex-wrap: wrap;
  }
  .procedureContentAbout{
    margin-top: 40px;
    width: 100%;
  }
  .procedureContent{
    width: 100%;
    max-width: 695px;
  }
  .decorAdv{
    width: 50%;
    text-align: center;
    margin: 0 auto;
    margin-top: 40px;
  }
  .decorAdv_btn {
    margin: 0 auto;
    margin-top: 30px;
  }
  .applicationFormsAdd{
    display: none;
  }
  .settings__answer {
    position: absolute;
    right: 0px;
    left: -170px;
    background: #fff;
    z-index: 3;
    width: 340px;
    top: 120%;
    transform: scaleY(0);
    transform-origin: top center;
    &.active {
      opacity: 1;
      transform: scaleY(1);
    }
  }
  .confirmBlock{
    flex-wrap: wrap;
  }
  .confirmBlock__column{
    width: 100%;
    &:last-child{
      width: auto;
      margin: 0 auto;
    }
  }
  .payment{
    width: 95%;
  }
  .payment__list{
    margin-top: 50px;
    justify-content: space-around;
    margin-top: 20px;
  }
  .payment__btn {
    margin-top: 10px;
  }
  .payment__about {
    margin-top: 20px;
  }
  .faqInf{
    width: 100%;
    text-align: center;
  }
  .faq__video {
    margin: 0 auto;
    margin-top: 45px;
  }
  .faq__link {
    margin: 0 auto;
    margin-top: 30px;
  }
  .faq{
    margin-top: 35px;
  }
  .faq__question{
    justify-content: center;
  }
  .twosign{
    width: 95%;
    max-width: 820px;
  }
  .twosign{
    padding: 30px;
  }
  .attention__text {
    width: 90%;
  }
  .attention__content{
    width: 95%;
  }
  .change{
    width: 95%;
    max-width: 820px;
    padding: 40px;
  }
  .change__blocks {
    justify-content: space-around;
  }
  .change__blocks{
    margin-top: 10px;
  }
  .change__block{
    margin-top: 20px;
  }
  .passport{
    width: 95%;
    padding: 35px 40px;
  }
  .formLineOver{
    width: 100%;
    overflow-x: auto;
    padding-bottom: 40px;
    &::-webkit-scrollbar {
      background: transparent;
      width: 5px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #b1b1b1;
    }
    &_nb{
      padding-bottom: 0;
    }
  }
  .formLineOver_mobMt{
    margin-top: 10px;
  }
  .formLine{
    width: 1100px;
    &_little{
      width: 680px;
    }
    &_middle{
      width: 850px;
    }
  }
  .breadCrumbs__btns{
    bottom: -40px;
    top: auto;
  }
  .application_mt{
    margin-top: 50px;
  }
  // .login-btn {
  //   width: unset;
  // }
}
@media (max-width: 760px){
  .wrapper__middle{
    width: 95%;
  }
  .about {
    padding: 14px 60px 14px 14px;
  }
  .title{
    font-size: 30px;
  }
  .about__closeBtn {
    top: 30px;
    right: 30px;
  }
  .procedureContentList{
    width: 100%;
    justify-content: space-around;
  }
  .map {
    order: 1;
    padding-left: 0;
    border-left: none;
  }
  .map_ml{
    margin-top: 0;
    margin-left: 0;
  }
  .header__nav {
    order:2;
    margin-top: 15px;
  }
  .search  {
    order: 3;
  }
  .user  {
    order: 4;
  }
  .header_abs {
    width: 70%;
  }
  .decorContent{
    width: 100%;
  }
  .decorContentAbout__content{
    width: 85%;
  }
  .decorAdvTitles {
    margin: 10px 0;
  }
  .decorAdv__list{
    margin: 0;
  }
  .inputfile__text{
    font-size: 16px;
  }
  .applicationTextsBl__input{
    width: 312px;
    margin-top: 10px;
  }
  .applicationTextsBl{
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .application__about {
    margin-left: 5px;
  }
  .application__or {
    display: block;
    margin: 0 15px;
  }
  .applicationForms {
    width: 100%;
    max-width: 580px;
  }
  .application__checkboxs{
    margin-top: 30px;
  }
  .applicationForms__inputs {
    margin-top: 20px;
  }
  .applicationForms__inputsList{
    margin-top: 0;
  }
  .settings__block{
    width: 100%;
    max-width: 640px;
    padding: 25px 30px;
  }
  .settings__list{
    margin-top: 30px;
  }
  .settings__parametr, .settings__value{
    font-size: 15px;
    line-height: 100%;
  }
  .link_20{
    font-size: 18px;
    line-height: 100%;
  }
  .settings__btn, .settings__btn_2  {
    font-size: 17px;
    line-height: 100%;
  }
  .settings__value_v2{
    width: 68%;
  }
  .checking__position{
    position: static;
  }
  .titleBlock {
    padding: 14px 0;
    font-size: 17px;
  }
  .settings__title {
    font-size: 24px;
  }
  .settings__answer {
    left: 0;
    right: 0;
    margin: auto;
    width: 340px;
  }
  .settings__item_absStyle, .settings__item_abs {
    position: relative;
  }
  .settings__qustion{
    position: static;
  }
  .confirmBlock{
    margin-top: 36px;
  }
  .title_53{
    font-size: 40px;
    line-height: 100%;
  }
  .faq__video{
    width: 100%;
    max-width: 540px;
  }
  .password_mt{
    margin-top: 20px;
  }
  .password__inputBlock{
    width: 100%;
    margin-top: 10px;
  }
  .adress{
    width: 95%;
    max-width: 640px;
    padding: 20px 30px;
  }
  .adress__inputBlock_mt{
    margin-top: 20px;
  }
  .adress__inputBlock_little{
    margin-top: 20px;
    width: 140px;
  }
  .passport__btn {
    margin: 0 auto;
    margin-top: 20px;
  }
  .breadCrumbs__item{
    margin-left: 8px;
  }
  .breadCrumbs__right{
    margin-right: 8px;
  }
  .confirmBlock_btn {
    margin-top: 30px;
    font-size: 20px;
    line-height: 100%;
    padding: 16px 24px;
  }
  .passport__datas{
    margin-left: -15px;
  }
  .passport__data{
    margin-left: 15px;
  }
  .passport__value{
    font-size: 20px;
    line-height: 100%;
  }
  .mt_60{
    margin-top: 40px;
  }
  .formLine__item{
    &_102{
      width: 92px;
    }
    &_158{
      width: 148px;
    }
    &_170{
      width: 160px;
    }
    &_200{
      width: 180px;
    }
    &_230{
      width: 200px;
    }
    &_258{
      width: 218px;
    }
    &_276{
      width: 236px;
    }
    &_410{
      width: 360px;
    }
    &_440{
      width: 370px;
    }
  }
  .formLine {
    width: 950px;
    &_little{
      width: 620px;
    }
    &_middle{
      width: 780px;
    }
  }
  .applicationTitle {
    text-align: left;
  }
}
@media (max-width: 510px){
  .footer{
    width: 100%;
  }
  .title_28{
    font-size: 23px;
  }
  .procedureContent__name {
    width: 140px;
    text-align: left;
  }
  .text_18{
    font-size: 14px;
  }
  .procedureContent__tab_op{
    width: 26px;
    height: 26px;
    font-size: 14px;
  }
  .procedureContent__tabText{
    display: none;
  }
  .procedureContent__icon{
    display: block;
  }
  .procedureUsersText{
    margin-left: 20px;
  }
  .procedureContent__item{
    margin-left: 15px;
  }
  .procedureUser__photo {
    width: 50px;
    height: auto;
  }
  .procedureUser__add {
    width: 26px;
    height: 26px;
  }
  .procedure__icon {
    width: 16px;
    height: auto;
  }
  .procedureContent__tab{
    padding: 0 6px;
    &::after{
      bottom: -5px;
    }
  }
  .procedureContent__tabContents {
    margin-top: 4px;
  }
  .procedureContent__tabTitle{
    .title_28 {
      font-size: 20px;
    }
  }
  .procedureContentList{
    flex-direction: column;
    margin-top: 34px;
  }
  .procedureContentItem{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 30px;
    &::before{
      position: static;
      margin: 0;
      margin-right: 19px;
    }
  }
  .procedureContentIcon {
    width: 45px;
    height: auto;
  }
  .procedureContentTexts{
    text-align: left;
    margin-top: 0;
    margin-left: 16px;
  }
  .footer__link {
    font-size: 9px;
  }
  .procedureContentAbout{
    margin-top: 20px;
  }
  .procedureContentAbout__text {
    margin: 15px 0;
  }
  .procedureContentAbout__more{
    margin-bottom: 15px;
  }
  .header_abs {
    width: 100%;
    padding: 5% 2.5%;
    height: calc(100% - 47px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    align-self: flex-start;
  }
  .user {
    position: absolute;
    left: 2.5%;
    bottom: 5%;
  }
  .header__nav{
    margin-top: 30px;
  }
  .search {
    margin-top: 25px;
  }
  .decorContent__line{
    height: 70px;
  }
  .decorAdv{
    margin-top: 30px;
    width: 80%;
  }
  .decorAdv_btn {
    margin-top: 15px;
  }
  .decorContentText__about {
    margin-left: 0;
    width:80%;
    text-align: left;
  }
  .decorContentAbout {
    padding: 20px 18px;
  }
  .decorContentAbout__content{
    width: 80%;
  }
  .decorContent__textItem{
    .text_14{
      width: 85%;
    }
  }
  .decorContent__checkboxs{
    flex-direction: column;
  }
  .decorContent__checkbox{
    margin-left: 0;
    margin-top: 10px;
    &:first-child{
      margin-top: 0;
    }
  }
  .application__checkboxs{
    width: 100%;
  }
  .applicationForms__abs{
    bottom: -12px;
  }
  .applicationForms__inputs_big{
    width: 100%;
  }
  .application__checkboxs{
    margin-top: 18px;
  }
  .decorContentText__price{
    font-size: 14px;
  }
  .procedureContent__tabContent_docs{
    justify-content: space-around;
    padding: 20px;
    padding-top: 10px;
  }
  .procedureContent__tabContent_doc{
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
  }
  .procedureContentLink{
    display: flex;
    align-items: center;
  }
  .settings__parametr, .settings__value{
    font-size: 13px;
    line-height: 100%;
  }
  .settings__title {
    font-size: 22px;
    line-height: 100%;
  }
  .settings__item{
    margin-top: 22px;
  }
  .settings__link{
    font-size: 16px;
  }
  .settings__btn, .settings__btn_2 {
    font-size: 14px;
    line-height: 100%;
  }
  .settings__plus {
    width: 18px;
    height: auto;
  }
  .settings__item_absStyle{
    width: 100%;
  }
  .settings__item_absStyle .settings__qustion {
    margin-left: 4px;
  }
  .settings__item_absStyle .settings__link{
    width: 90%;
  }
  .confirmBlock__datas{
    flex-wrap: wrap;
    margin-top: 0px;
  }
  .confirmBlock__data{
    width: 100%;
    margin-top: 20px;
  }
  .confirmBlock__indefication{
    margin-top: 4px;
  }
  .confirmBlock__column{
    padding: 20px 15px;
  }
  .confirmBlock__text{
    margin-top: 20px;
  }
  .payment__btn {
    width: 100%;
  }
  .payment__icon{
    width: 70px;
    height: auto;
  }
  .payment__text {
    margin-top: 15px;
  }
  .payment__texts{
    width: 70%;
  }
  .payment__title {
    font-size: 22px;
    line-height: 100%;
  }
  .breadCrumbs__list {
    flex-wrap: wrap;
    margin-left: -8px;
  }
  .breadCrumbs__item:first-child{
    margin-left: 8px;
  }
  .breadCrumbs__link{
    font-size: 13px;
    line-height: 100%;
  }
  .faq__link{
    margin-top: 20px;
  }
  .faq__titleReg {
    margin-top: 18px;
  }
  .faq__question{
    font-size: 16px;
    line-height: 100%;
  }
  .faq__answer{
    font-size: 16px;
    line-height: 22px;
  }
  .footer__item_entrance{
    .footer__link {
      font-size: 14px;
    }
  }
  .attention{
    padding: 28px 0;
  }
  .attention__text{
    width: 85%;
    font-size: 16px;
    line-height: 24px;
  }
  .twosign{
    padding: 20px 10px;
  }
  .attention_mt{
    margin-top: 20px;
  }
  .twoBtns__btn {
    font-size: 17px;
    line-height: 17px;
    padding: 16px 20px;
  }
  .password__inputBlock{
    img{
      width: 100%;
      height: auto;
    }
  }
  .twoBtns{
    margin-top: 30px;
  }
  .change{
    padding: 25px;
  }
  .change__block {
    padding: 28px 20px;
    width: 100%;
    max-width: 340px;
  }
  .change__inputs{
    margin-top: 40px;
  }
  .adress__inputBlock_little{
    width: 100%;
    margin-left: 0;
  }
  .adress__moreInputs{
    margin-left: 0;
  }
  .adress {
    padding: 15px 20px;
  }
  .twoBtns_v2 .button_blue {
    margin-left: 10px;
  }
  .passport{
    padding: 25px 30px;
  }
  .passport__datas{
    margin-top: -10px;
  }
  .change__texts{
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
  }
  .passport__btn{
    padding: 17px 23px;
    font-size: 20px;
    line-height: 100%;
  }
  .applicationForms__inputs_big2{
    width: 100%;
  }
  .checkbox_ml{
    margin-left: 20px;
    text-align: left;
  }
  .checkbox__text{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .checkbox__textInner{
    width: 90%;
    display: block;
    margin-left: 6px;
  }
  .formLine__item{
    &_102{
      width: 82px;
    }
    &_158{
      width: 110px;
    }
    &_170{
      width: 130px;
    }
    &_200{
      width: 140px;
    }
    &_230{
      width: 165px;
    }
    &_258{
      width: 178px;
    }
    &_276{
      width: 186px;
    }
    &_410{
      width: 300px;
    }
    &_440{
      width: 320px;
    }
  }
  .formLine {
    width: 800px;
    &_little{
      width: 520px;
    }
    &_middle{
      width: 680px;
      .formLine__item_102 {
        width: 90px;
      }
    }
  }
  .breadCrumbs__btns{
    width: 90%;
  }
  .application__form_p{
    padding-bottom: 30px;
  }
  .formLine__input_textarea{
    height: 55px;
  }
  .text_18_21{
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 394px){
  .applicationForms__inputs {
    width: 100%;
  }
  .applicationForms__inputs_little {
    width: 99px;
  }
  .applicationForms__inputs_little_2 {
    width: 134px;
  }
  .applicationForms__input{
    font-size: 18px;
    line-height: 18px;
    &::placeholder{
      font-size: 18px;
    }
  }
  .application__about {
    font-size: 17px;
  }
  .applicationForms_wr {
    padding: 14px;
  }
  .settings__item{
    flex-wrap: wrap;
  }
  .settings__qustion {
    font-size: 15px;
    line-height: 15px;
    width: 20px;
    height: 20px;
  }
  .settings__parametr, .settings__value {
    width: 100%;
  }
  .settings__value{
    display: block;
    margin-top: 6px;
  }
  .settings__titles{
    flex-wrap: wrap;
    .link_20 {
      display: block;
      margin-top: 5px;
    }
  }
  .settings__btn, .settings__btn_2 {
    text-align: left;
  }
  .settings__title {
    font-size: 18px;
    line-height: 100%;
  }
  .settings__texts {
    font-size: 14px;
    line-height: 22px;
    .link_20{
      font-size: 14px;
    }
  }
  .settings__dop{
    margin-top: 10px;
  }
  .settings__value_v2{
    display: flex;
    align-items: center;
  }
  .settings__link {
    font-size: 15px;
  }
  .settings__block {
    padding: 20px;
  }
  .settings__answer {
    width: 95%;
  }
  .settings__dop {
    padding: 15px 30px 15px 15px;
  }
  .settings__closeIcon {
    width: 16px;
    height: auto;
  }
  .settings__close {
    top: 10px;
    right: 10px;
  }
  .settings__btn {
    margin-top: 25px;
  }
  .settings__btn_2{
    margin-top: 15px;
  }
  .title_53 {
    font-size: 36px;
  }
  .change__assessments{
    margin-top: 15px;
  }
  .change__pravImages{
    margin-top: -15px;
  }
  .adress {
    padding: 15px 20px;
  }
  .adress__delete {
    width: 100%;
  }
  .twoBtns_v2 .button_transparent {
    margin-left: 0;
    margin-top: 10px;
  }
  .twoBtns_v2 .button_blue {
    margin-left: auto;
    margin-top: 10px;
  }
  .passport {
    padding: 20px 25px;
  }
  .twoBtns__btn {
    font-size: 16px;
    line-height: 16px;
    padding: 14px 18px;
  }
}
@media (max-width: 350px){
  .wrapper__little{
    width: 95%;
  }
  .confirmBlock{
    margin-top: 15px;
  }
}
