.payment {
  width: 1020px;
  margin: 0 auto;
  text-align: left;
}
.payment_mt {
  margin-top: 40px;
}
.payment__about {
  display: block;
  margin-top: 35px;
}
.payment__btn {
  background: #fff;
  border: 2px solid #935677;
  width: 488px;
  outline: none;
  cursor: pointer;
  padding: 36px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: rgb(240, 240, 240);
  }
}
.payment__list {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  margin-top: 78px;
}
.payment__text {
  margin-top: 25px;
}
.payment__texts {
  width: 265px;
  margin-left: 27px;
  text-align: left;
}
