@import "styles/vars";
@import "styles/mixins";

.container {
  width: 100%;
  max-width: calc(100% - 26%);
  margin: 0px auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // background:rgb(229, 229, 229, 0.3);

  .section {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 49px;
      color: #000000;
      max-width: 634px;
      margin-left: 51px;
      @include text("Pragmatica", 36, 49);

      @media (max-width: 768px) {
        @include text("Pragmatica", 24, 33);
        max-width: 80%;
      }

      @media (max-width: 600px) {
        @include text("Pragmatica", 24, 33);
        max-width: 100%;
        margin-left: 23px;
      }
    }

    .content-container {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
      border-radius: 29px;
    }
  }

  .section-first {
    margin-top: 42px;

    &__content-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 52px 0px 32px 52px;

      .content-container__left {
        width: 50%;
        transform: translateY(-20px);

        @media (max-width: 960px) {
          transform: none;
        }

        .title {
          @include text("Pragmatica", 48, 66);
          margin-bottom: 40px;
          font-weight: 600;

          @media (max-width: 1200px) {
            @include text("Pragmatica", 38, 53);
            margin-bottom: 20px;
          }
        }

        .content {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22.4px;
        }
      }

      .content-container__right {
        width: 50%;
        text-align: right;
        padding-left: 25px;
        padding-top: 25px;

        img {
          object-fit: contain;
          max-width: 100%;
        }
      }

      @media (max-width: 860px) {
        padding-top: 32px;
      }
    }

    @media (max-width: 860px) {
      margin-top: 12px;

      .content-container {
        flex-direction: column;

        .content-container__right {
          width: 100%;
          padding-right: 0;

          img {
            width: 100%;
            height: 100%;
            // transform: translateX(-8%);
          }
        }

        .content-container__left {
          width: 100%;
          padding-right: 30px;
        }
      }
    }

    @media (max-width: 480px) {
      .content-container {
        flex-direction: column;
        padding-left: 25px;
      }
    }

    @media (max-width: 420px) {
      .content-container > .content-container__left > .title {
        @include text("Pragmatica", 30, 45);
      }
    }
  }

  .section-video {
    margin-top: 70px;
    padding: 0;

    &.notplaying {
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-35%, -50%);
        border: 0;
        box-sizing: border-box;
        height: 74px;

        border-color: transparent transparent transparent #0075db;
        transition: 100ms all ease;
        cursor: pointer;
        border-style: solid;
        border-width: 37px 0 37px 60px;

        @media (max-width: 860px) {
          height: 40px;
          border-width: 22px 0 22px 35px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        box-sizing: border-box;
        height: 144px;
        width: 144px;
        box-shadow: 0px 13px 25px rgb(0 117 219 / 25%);
        background: #fff;

        @media (max-width: 860px) {
          height: 94px;
          width: 94px;
        }
      }

      &:hover {
        &::after {
          border-color: transparent transparent transparent #3f95fd;
        }
      }
    }

    video {
      width: 100%;
      max-width: 960px;
      margin: auto;
      border-radius: 36px;
      box-shadow: 0px 4px 25px rgb(0 0 0 / 5%);
      border: 20px white solid;
    }

    @media (max-width: 860px) {
      margin-top: 50px;
      padding: 0 20px;
    }
  }

  .section-second {
    margin-top: 79px;

    &__content-container {
      margin-top: 31px;
      padding: 46px 52px;

      .content-container__text {
        max-width: 615px;
        width: 100%;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
      }
    }

    @media (max-width: 860px) {
      width: calc(100% - 46px);
      margin-top: 47px;

      &__content-container {
        padding: 23px 25px;
      }

      .section-second__title {
        margin-left: 0;
        font-family: Pragmatica;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
      }
    }
  }

  .section-third {
    margin-top: 65px;

    &__content-container {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .content-container__top {
        padding: 41px 52px;
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 352px;
        align-items: flex-start;

        .top__left {
          width: calc(60% - 50px);

          .section:last-child {
            margin-top: 28px;
          }

          .section {
            &__title {
              font-family: Pragmatica;
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 140%;
            }

            &__content {
              margin-top: 25px;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 140%;
            }
          }

          .large-hidden {
            display: none;
          }
        }

        .top__right {
          margin-left: 50px;
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .section {
            &__title {
              font-family: Pragmatica;
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 140%;
            }

            &__content {
              margin-top: 25px;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 140%;
            }
          }

          img {
            margin-top: 55px;
            max-width: 265px;
            width: 100%;
            object-fit: contain;
          }
        }

        @media (max-width: 860px) {
          padding-top: 20px;
          padding-bottom: 10px;
          flex-direction: column;

          .top__left {
            width: 100%;

            .section__title {
              font-family: Pragmatica;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 22px;
            }

            .large-hidden {
              display: block;
              margin-top: 25px;
            }
          }

          .top__right {
            width: 100%;
            margin-left: 0;

            .section {
              display: none;
              // margin-top: 25px;
            }

            .section__title {
              font-family: Pragmatica;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 22px;
            }

            img {
              display: none;
            }
          }
        }
      }

      .content-container__bottom {
        display: flex;
        flex-direction: column;
        width: calc(100% - 60px);
        align-self: center;
        min-height: 267px;
        align-items: flex-start;
        border: 1px solid #0061fe;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.05));
        border-radius: 32px;
        margin-bottom: 23px;
        padding: 20px;

        .bottom__top {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          width: 100%;

          .section {
            width: 48%;
            display: block;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
          }
        }
        .bottom__bottom {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          width: 100%;
          margin-top: 25px;

          .section {
            width: 48%;
            display: block;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
          }
        }

        @media (max-width: 860px) {
          border: none;
          .bottom__bottom {
            flex-direction: column;

            .section {
              width: 100%;
            }
          }

          .bottom__top {
            flex-direction: column;

            .section {
              width: 100%;
            }
          }
        }
      }

      @media (max-width: 860px) {
        margin-top: 15px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    margin-left: 52px;

    .title {
      width: 58%;
      font-family: Pragmatica;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }

    .content {
      width: 40%;
      font-family: Pragmatica;
      font-style: normal;
      font-weight: 800;
      font-size: 64px;
      line-height: 89px;
    }

    @media (max-width: 1150px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0px;

      .title {
        width: 78%;
        // display: flex;
        justify-content: center;
        font-family: Pragmatica;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }

      .content {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 860px) {
    max-width: 100%;
    background-color: #ffffff;
  }
}

.footer-container {
  width: 100%;
  max-width: calc(100% - 26%);
  margin: 41px auto 0px auto;
  padding-left: 52px;

  &__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .footer-section__title {
      width: 38%;
      font-family: Pragmatica;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 49px;
    }

    .footer-section__content {
      width: 60%;
      background: #ffffff;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
      border-radius: 29px;
      padding: 49px 15px 49px 52px;

      .text {
        font-family: Pragmatica;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  @media (max-width: 860px) {
    max-width: 100%;
    background-color: #ffffff;
    margin-top: 0;
    padding-top: 30px;
    padding-left: 0px;

    &__section {
      flex-direction: column;
      justify-content: center;

      .footer-section__title {
        width: calc(100% - 46px);
        font-family: Pragmatica;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
      }

      .footer-section__content {
        width: calc(100% - 46px);
        padding: 18px 27px;
      }
    }
  }
}

.but {
  color: #0061fe;
  // font-size: 16px;
  font-weight: bold;
  @include text("Ubuntu", 17, 17);
}


.top-phone-number {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
    gap: 30px;
    align-items: center;

    @media (min-width: 768px) {
      margin-bottom: 30px;
    }
}

.top-phone-number a {
  color: inherit;
}
